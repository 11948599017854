.subscribe-container-header {
  display: inline-flex;
  align-items: center;
  position: relative;
}

.subscribe-button-header {
  background: linear-gradient(
    45deg,
    #FFD700,
    #FDB931,
    #FFD700,
    #FDB931,
    #FFD700
  );
  background-size: 400% 400%;
  color: #000;
  border: 2px solid #B8860B;
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
  animation: shimmer 6s ease infinite;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  text-shadow: 0 1px 1px rgba(255,255,255,0.5);
}

.subscribe-button-header:hover {
  background-size: 100% 100%;
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  border-color: #DAA520;
}

.subscribe-button-header:active {
  transform: translateY(1px);
  box-shadow: 0 1px 2px rgba(0,0,0,0.1);
}

@keyframes shimmer {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.loading-spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: white;
  animation: spin 1s linear infinite;
}

.error-message {
  position: absolute;
  top: 100%;
  left: 0;
  color: #dc3545;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  padding: 4px 8px;
  border-radius: 4px;
  margin-top: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 1000;
}

@keyframes spin {
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.subscribe-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

.subscribe-button {
  background-color: black;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 200px;
}

.subscribe-button:hover {
  background-color: #333;
} 